import React, { useEffect } from "react";
import Admin from "./admin-dashboard/layouts/Admin.js";
import { ToastContainer } from "react-toastify";
import App from "./App";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
// import { defaultAddress } from "./contract/addresses";
import { injected } from "./connect/connector";
import { defaultAddress } from "./utils.js";
import "react-toastify/dist/ReactToastify.css";
export default function Main() {
  const { active, account, activate, library, deactivate } = useWeb3React();
  useEffect(() => {
    const storage = localStorage.getItem("wallet");
    if (storage == "meta") {
      activate(injected);
    }
  }, []);
  return (
    <div>
      <BrowserRouter>
        <ToastContainer />
        <Switch>
          <Route path="/admin">
            {account &&
            account.toLowerCase() == defaultAddress.toLowerCase() ? (
              <Admin />
            ) : (
              <Redirect to="/" />
            )}
          </Route>
          <Route path="/">
            <App />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}
