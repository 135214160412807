import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { url } from "../../../utils";
import TableCell from "@material-ui/core/TableCell";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

// core components
import styles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const { tableHead, tableData, tableHeaderColor, setloading, init, search } =
    props;
  const classes = useStyles();
  const del = async (id) => {
    try {
      setloading(true);

      const { data } = await axios.post(`${url}/project/delete`, { id });

      if (data.status) {
        toast.success(data.message);
        setloading(false);
        init();
      } else {
        toast.error(data.message);
        setloading(false);
        // setaddcoinModal(false);
      }
    } catch (error) {
      setloading(false);
      console.error("addToken", error);
    }
  };
  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {tableData?.length !== 0 ? (
            tableData.map(
              (
                {
                  _id,
                  projectName,
                  projectDescription,
                  website,
                  twitter,
                  telegram,
                  logoPath,
                  pdfPath,
                  addedAt,
                },
                key
              ) => (
                <TableRow key={key} className={classes.tableBodyRow}>
                  <TableCell className={classes.tableCell} key={key}>
                    {_id}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    <img width="50px" src={`${url}/${logoPath}`} alt="" />
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    <a href={`${url}/${pdfPath}`} download>
                      {" "}
                      Pdf File
                    </a>
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {projectName}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {projectDescription}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {website}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {twitter}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {telegram}
                  </TableCell>
                  <TableCell className={classes.tableCell} key={key}>
                    {moment(addedAt).format("MM/DD/YYYY HH:MMa")}
                    <Link to={`/admin/edit-project/${_id}`}>
                      <IconButton>
                        <EditIcon
                          style={{ cursor: "pointer", color: "blue" }}
                        />
                      </IconButton>
                    </Link>
                    <IconButton>
                      <DeleteIcon
                        style={{ cursor: "pointer", color: "red" }}
                        onClick={() => del(_id)}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <TableRow className={classes.tableBodyRow}>
              {!search ? (
                <TableCell
                  colSpan="7"
                  align="center"
                  className={classes.tableCell}
                >
                  No project is added yet.
                </TableCell>
              ) : (
                <TableCell
                  colSpan="7"
                  align="center"
                  className={classes.tableCell}
                >
                  No project found with "{search}".
                </TableCell>
              )}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray",
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};
