import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import CompanyLogo from "./components/CompanyLogo";
import Advantages from "./components/Advantages";
import About from "./components/About";
import Services from "./components/Services";
import WhyUs from "./components/WhyUs";
import Process from "./components/Process";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Connect from "./connect/connect";
import { useWeb3React } from "@web3-react/core";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import theme from "./theme";
function App() {
  const { account, active, deactivate, activate } = useWeb3React();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <Route exact path="/">
        <Home />
        <CompanyLogo />
        <Advantages />
        <About />
        <Services />
        <WhyUs />
        <Process />
        <Contact />
      </Route>
      <Route exact path="/connect">
        <Connect />
      </Route>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
