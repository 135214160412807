import { Box, Button, Container, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { url } from "../utils";

export default function Projects() {
  const [flip, setflip] = useState(false);
  const [width, setwidth] = useState(false);
  const { spacing } = useTheme();
  useEffect(() => {
    if (flip) {
      setTimeout(() => {
        setwidth(true);
      }, 400);
    } else {
      setTimeout(() => {
        setwidth(false);
      }, 700);
    }
  }, [flip]);
  const [projects, setprojects] = useState([]);

  const init = async () => {
    const res = await axios.get(`${url}/project`);
    console.log([...res.data.data]);
    setprojects([...res.data.data]);
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <Container maxWidth="xl">
      <Grid container>
        {projects.map(
          ({
            _id,
            projectName,
            projectDescription,
            website,
            twitter,
            telegram,
            logoPath,
            pdfPath,
          }) => (
            <Grid key={_id} item xs={12} sm={6} md={4}>
              <div
                class={width ? "card-container1" : "card-container"}
                onClick={() => setflip(!flip)}
              >
                <div class={flip ? "card is-flipped" : "card"}>
                  <div class="card-faces back">
                    <div class="logo-back">
                      <img width="100%" src={`${url}/${logoPath}`} alt="" />
                    </div>
                    <h4 class="back-title">
                      {projectName}
                      <Box mt={3}>
                        <a
                          target="_blank"
                          style={{ textDecoration: "none", color: "#fff" }}
                          textDecoration="none"
                          href={website}
                        >
                          <Box
                            component="span"
                            p={spacing(0.1)}
                            borderRadius="100%"
                            fontSize={spacing(2)}
                            className="fas fa-globe"
                            sx={{
                              bgcolor: "#383838",
                              "&:hover": {
                                color: "rgba(255, 184, 0, 1)",
                              },
                            }}
                          ></Box>
                        </a>
                        <a
                          target="_blank"
                          style={{ textDecoration: "none", color: "#fff" }}
                          textDecoration="none"
                          href={twitter}
                        >
                          <Box
                            component="span"
                            ml={spacing(0.1)}
                            p={spacing(0.1)}
                            borderRadius="100%"
                            bgcolor="#383838"
                            fontSize={spacing(2)}
                            className="fab fa-twitter"
                            sx={{
                              bgcolor: "#383838",
                              "&:hover": {
                                color: "rgba(255, 184, 0, 1)",
                              },
                            }}
                          ></Box>
                        </a>
                        <a
                          target="_blank"
                          style={{ textDecoration: "none", color: "#fff" }}
                          textDecoration="none"
                          href={telegram}
                        >
                          <Box
                            component="span"
                            ml={spacing(0.1)}
                            p={spacing(0.1)}
                            borderRadius="100%"
                            bgcolor="#383838"
                            fontSize={spacing(2)}
                            className="fab fa-telegram-plane"
                            sx={{
                              bgcolor: "#383838",
                              "&:hover": {
                                color: "rgba(255, 184, 0, 1)",
                              },
                            }}
                          ></Box>
                        </a>
                      </Box>
                    </h4>
                    <h3 class="back-name">{projectDescription}</h3>

                    <a
                      href={`${url}/${pdfPath}`}
                      style={{
                        textDecoration: "none",
                        align: "center",
                        position: "absolute",
                        bottom: "10%",
                        left: "25%",
                        zIndex: "10000",
                      }}
                      download
                      target="_blank"
                    >
                      <Button
                        style={{
                          background: "#FFB800",
                          fontSize: "18px",
                          borderRadius: "9px",
                          width: "158px",
                          height: "45px",
                          color: "#fff",
                          border: "none",
                          textTransform: "capitalize",
                        }}
                      >
                        Download PDF
                      </Button>
                    </a>

                    {/*
                    <ul class="back-list">
                      <li class="back-list-item">
                        <i class="list-icon fas fa-map-marker-alt"></i> Unit 42,
                        6 Example Ave, 49418 U.K
                      </li>
                      <li class="back-list-item">
                        <i class="list-icon fas fa-mobile-alt"></i> +44 (0)7912
                        345678
                      </li>
                      <li class="back-list-item">
                        <i class="list-icon far fa-envelope"></i>{" "}
                        A.Whiz@imagine.com
                      </li>
                      <li class="back-list-item">
                        <i class="list-icon fas fa-phone-square"></i> 0800 123
                        4567
                      </li>
                      <li class="back-list-item">
                        <i class="list-icon fas fa-globe"></i>{" "}
                        www.twiter.com/AdamTheWizard
                      </li>
                    </ul> */}
                    <div class="blue-bar"></div>
                  </div>
                  <div class="card-faces front">
                    {!width && (
                      <div class="logo">
                        <img width="100%" src={`${url}/${logoPath}`} alt="" />
                      </div>
                    )}
                    <h4 class="front-title">{projectName}</h4>
                  </div>
                </div>
              </div>
            </Grid>
          )
        )}
      </Grid>
    </Container>
  );
}
