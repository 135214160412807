import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import { Box, Divider, Fab, IconButton } from "@material-ui/core";
import Button from "../../components/CustomButtons/Button.js";
import Loading from "../loading";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import StyledDropzone from "./PictureDrop";
import { url } from "../../../utils";
import DeleteIcon from "@material-ui/icons/Delete";
import { toast } from "react-toastify";
import axios from "axios";
const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
export default function Addnew() {
  const classes = useStyles();

  const [loading, setloading] = useState(false);
  const [file, setfile] = useState("");
  const [pdfFile, setpdfFile] = useState("");
  const [projectName, setprojectName] = useState("Bloctech");
  const [projectDescription, setprojectDescription] = useState("Something");
  const [websiteLink, setwebsiteLink] = useState("bloctech.com");
  const [twitterLink, settwitterLink] = useState("bloctech.com");
  const [telegramLink, settelegramLink] = useState("bloctech.com");

  const addProject = async () => {
    if (!projectName) {
      toast.error("Error! please enter project name.");
    } else if (!projectDescription) {
      toast.error("Error! please enter project description.");
    } else if (!websiteLink) {
      toast.error("Error! please enter website link.");
    } else if (!twitterLink) {
      toast.error("Error! please enter twitter link.");
    } else if (!telegramLink) {
      toast.error("Error! please enter telegram link.");
    } else if (!file) {
      toast.error("Error! please select project Logo.");
    } else if (!pdfFile) {
      toast.error("Error! please select project pdf file.");
    } else {
      try {
        setloading(true);
        const dataF = new FormData();
        dataF.append("file", file);
        dataF.append("pdfFile", pdfFile);
        dataF.append("projectName", projectName);
        dataF.append("websiteLink", websiteLink);
        dataF.append("twitterLink", twitterLink);
        dataF.append("telegramLink", telegramLink);
        dataF.append("projectDescription", projectDescription);
        const { data } = await axios.post(`${url}/project`, dataF);

        if (data.status) {
          toast.success(data.message);
          setloading(false);
        } else {
          toast.error(data.message);
          setloading(false);
        }
      } catch (error) {
        setloading(false);
        console.error("addToken", error);
      }
    }
  };
  return (
    <>
      <Loading open={loading} />
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Add new Project</h4>
          <p className={classes.cardCategoryWhite}>Add new Project from here</p>
        </CardHeader>

        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Project Name"
                id="company-disabled"
                formControlProps={{
                  fullWidth: true,
                }}
                error={!projectName}
                success={projectName}
                value={projectName}
                onChange={(e) => setprojectName(e.target.value)}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Project Description"
                id="username"
                formControlProps={{
                  fullWidth: true,
                }}
                error={!projectDescription}
                success={projectDescription}
                value={projectDescription}
                onChange={(e) => setprojectDescription(e.target.value)}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Website Link"
                id="email-address"
                formControlProps={{
                  fullWidth: true,
                }}
                error={!websiteLink}
                success={websiteLink}
                value={websiteLink}
                onChange={(e) => setwebsiteLink(e.target.value)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Twitter Link"
                id="first-name"
                formControlProps={{
                  fullWidth: true,
                }}
                error={!twitterLink}
                success={twitterLink}
                value={twitterLink}
                onChange={(e) => settwitterLink(e.target.value)}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Telegram Link"
                id="first-name"
                formControlProps={{
                  fullWidth: true,
                }}
                error={!telegramLink}
                success={telegramLink}
                value={telegramLink}
                onChange={(e) => settelegramLink(e.target.value)}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <StyledDropzone
                text="Drag 'n' drop Project Logo here, or click to select Project Logo."
                pdf={false}
                file={file}
                setfile={setfile}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <StyledDropzone
                text="Drag 'n' drop Project pdf file here, or click to select Project pdffile."
                pdf={true}
                file={pdfFile}
                setfile={setpdfFile}
              />
            </GridItem>
          </GridContainer>

          <Box align="center">
            <Button color="primary" onClick={addProject}>
              Add Project
            </Button>
          </Box>
        </CardBody>
      </Card>
    </>
  );
}
