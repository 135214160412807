import React, { useState } from "react";
import { Box, Container, Grid, useMediaQuery } from "@material-ui/core";
import Ellipse from "../images/Ellipse.svg";
import process from "../images/process.png";

function Process() {
  const matches = useMediaQuery("(max-width:760px)");
  return (
    <Box id="process" bgcolor="#0D0E24" height="auto" pt={10} pb={5}>
      <Box
        fontSize={{ xs: "30px", sm: "50px", lg: "41px" }}
        color="#ff8b00"
        p={0}
        align="center"
      >
        Our{" "}
        <span style={{ color: "#fff", fontWeight: "400", fontStyle: "normal" }}>
          Process
        </span>
        <img
          width="16px"
          height="16px"
          style={{ marginLeft: "10px" }}
          src={Ellipse}
        />
      </Box>
      <Box
        ml="auto"
        align="right"
        style={{
          overflowX: "scroll",
          height: "400px",
          //   display: "flex",
          //   alignItems: "center",
          //   justifyContent: "flex-end",
        }}
        width={{ xs: "100%", sm: "95%" }}
        position="relative"
        // p="60px 0px"
        mt={4}
      >
        <img
          style={{
            width: "1300px",
            position: "absolute",
            top: "0%",
            left: matches ? "-30px" : "110px",
          }}
          src={process}
          alt=""
        />
        <Box
          position="absolute"
          //   top={{ xs: "-60%", sm: "-40%" }}
          top="60%"
          left={matches ? "20px" : "160px"}
          align="center"
          width="200px"
          zIndex="1"
          letterSpacing={{ xs: "0.24rem", sm: "0.2em" }}
          id="tokenomics"
          fontSize={{ xs: "20px", sm: "20px" }}
        >
          Request Call
        </Box>
        <Box
          position="absolute"
          //   top={{ xs: "-60%", sm: "-40%" }}
          top="60%"
          left={matches ? "220px" : "360px"}
          align="center"
          width="200px"
          zIndex="1"
          letterSpacing={{ xs: "0.24rem", sm: "0.2em" }}
          id="tokenomics"
          fontSize={{ xs: "20px", sm: "20px" }}
        >
          Consultation
        </Box>
        <Box
          position="absolute"
          //   top={{ xs: "-60%", sm: "-40%" }}
          top="60%"
          left={matches ? "420px" : "560px"}
          align="center"
          width="200px"
          zIndex="1"
          letterSpacing={{ xs: "0.24rem", sm: "0.2em" }}
          id="tokenomics"
          fontSize={{ xs: "20px", sm: "20px" }}
        >
          Planning
        </Box>
        <Box
          position="absolute"
          //   top={{ xs: "-60%", sm: "-40%" }}
          top="60%"
          left={matches ? "620px" : "760px"}
          align="center"
          width="200px"
          zIndex="1"
          letterSpacing={{ xs: "0.24rem", sm: "0.2em" }}
          id="tokenomics"
          fontSize={{ xs: "20px", sm: "20px" }}
        >
          Working
        </Box>
        <Box
          position="absolute"
          //   top={{ xs: "-60%", sm: "-40%" }}
          top="60%"
          left={matches ? "820px" : "960px"}
          align="center"
          width="200px"
          zIndex="1"
          letterSpacing={{ xs: "0.24rem", sm: "0.2em" }}
          id="tokenomics"
          fontSize={{ xs: "20px", sm: "20px" }}
        >
          Edit
        </Box>
        <Box
          position="absolute"
          //   top={{ xs: "-60%", sm: "-40%" }}
          top="60%"
          left={matches ? "1040px" : "1180px"}
          align="center"
          width="200px"
          zIndex="1"
          letterSpacing={{ xs: "0.24rem", sm: "0.2em" }}
          id="tokenomics"
          fontSize={{ xs: "20px", sm: "20px" }}
        >
          Deliver
        </Box>
      </Box>
    </Box>
  );
}

export default Process;
