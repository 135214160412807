import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  Button,
  useMediaQuery,
  Typography,
  InputBase,
} from "@material-ui/core";
import Ellipse from "../images/Ellipse.svg";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import contact from "../images/contact.svg";
import Fade from "react-reveal/Fade";
import { url } from "../utils";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "../admin-dashboard/views/loading";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "90%",
      color: "#fff",
    },
  },
  textAdditional: {
    color: "#fff",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 300,
    fontSize: 20,
    marginTop: 20,
    textAlign: "left",
  },
  textField: {
    color: "#fff",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 300,
    fontSize: 20,
    borderStyle: "solid",
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomColor: "#fff",
    marginTop: 10,
    width: "100%",
  },
  textFieldMessage: {
    backgroundColor: "#3B385A",
    color: "#fff",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 300,
    fontSize: 20,
    marginTop: 10,
    borderRadius: 5,
    padding: 10,
    width: "100%",
  },
}));

function Contact() {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width:960px)");
  const [name, setname] = useState("");
  const [Cname, setCname] = useState("");
  const [email, setemail] = useState("");
  const [link, setlink] = useState("");
  const [message, setmessage] = useState("");
  const [loading, setloading] = useState(false);

  const messageHandler = async () => {
    if (!name) {
      toast.error("Error! please enter your name.");
    } else if (!Cname) {
      toast.error("Error! please enter your company name.");
    } else if (!email) {
      toast.error("Error! please enter your email.");
    } else if (!link) {
      toast.error("Error! please enter yur social media contact link.");
    } else if (!message) {
      toast.error("Error! please enter your message.");
    } else {
      try {
        setloading(true);
        const dataF = {
          name,
          Cname,
          email,
          link,
          message,
        };
        const { data } = await axios.post(`${url}/consult`, dataF);

        if (data.status) {
          toast.success(data.message);
          setloading(false);
          setname("");
          setCname("");
          setemail("");
          setlink("");
          setmessage("");
        } else {
          toast.error(data.message);
          setloading(false);
        }
      } catch (error) {
        setloading(false);
        console.error("addToken", error);
      }
    }
  };
  return (
    <Box id="contact" bgcolor="#171834" height="auto" pb={5}>
      <Loading open={loading} />
      <Container maxWidth="lg">
        <Fade bottom>
          <Box
            display="flex"
            flexDirection="column"
            flexBasis="30%"
            justifyContent="center"
            textAlign="center"
          >
            <Box
              component="h1"
              fontWeight={700}
              style={{
                zIndex: "100",
                position: "relative",
                top: "132px",
                textShadow: " 6px 4px 0px #FFB800",
                color: "#171834",
                fontStyle: "normal",
                fontSize: "147px",
              }}
              my={0}
            >
              C
              <span
                style={{
                  marginRight: "5px",
                  color: "#fff",
                  fontSize: "37px",
                  textShadow: "none",
                  fontWeight: "400",
                  fontStyle: "normal",
                }}
              >
                {" "}
                onsultancy
              </span>
              <img
                width="16px"
                height="16px"
                style={{ marginLeft: "10px" }}
                src={Ellipse}
              />
            </Box>
            <Grid
              container
              justifyContent="center"
              style={{
                alignItems: "center",
                marginTop: "35px",
                background: "#1d1f38",
              }}
              spacing={1}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                style={{
                  position: "relative",
                  paddingTop: matches ? "50px" : "0px",
                }}
              >
                <Container
                  className={classes.root}
                  noValidate
                  autoComplete="off"
                >
                  <Box
                    display="flex"
                    justifyContent={{ xs: "center", sm: "space-between" }}
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <Box flexBasis={{ xs: "100%", sm: "45%" }}>
                      <Typography
                        className={classes.textAdditional}
                        variant="body1"
                        textAlign="left"
                      >
                        Name
                      </Typography>
                      <InputBase
                        fullWidth
                        className={classes.textField}
                        id="standard-basic"
                        variant="standard"
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                      />
                    </Box>
                    <Box flexBasis={{ xs: "100%", sm: "45%" }}>
                      <Typography
                        className={classes.textAdditional}
                        variant="body1"
                        textAlign="left"
                      >
                        Company Name
                      </Typography>
                      <InputBase
                        fullWidth
                        className={classes.textField}
                        id="standard-basic"
                        variant="standard"
                        value={Cname}
                        onChange={(e) => setCname(e.target.value)}
                      />
                    </Box>
                  </Box>
                  <Typography
                    className={classes.textAdditional}
                    variant="body1"
                  >
                    Email
                  </Typography>
                  <InputBase
                    type="email"
                    className={classes.textField}
                    id="standard-basic"
                    variant="standard"
                    fullWidth
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                  />
                  <Typography
                    className={classes.textAdditional}
                    variant="body1"
                  >
                    Wechat, Telegram , Or Other
                  </Typography>
                  <InputBase
                    className={classes.textField}
                    id="standard-basic"
                    variant="standard"
                    fullWidth
                    value={link}
                    onChange={(e) => setlink(e.target.value)}
                  />
                  <Typography
                    className={classes.textAdditional}
                    variant="body1"
                  >
                    Additional Message
                  </Typography>
                  <InputBase
                    className={classes.textFieldMessage}
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    value={message}
                    onChange={(e) => setmessage(e.target.value)}
                  />

                  <Button
                    bgcolor="#3B385A"
                    style={{
                      textTransform: "capitalize",
                      boxShadow: "3px 3px 0px 4px #171834",
                      borderRadius: "106px",
                      fontSize: "20px",
                      width: "292px",
                      height: "74px",
                    }}
                    onClick={messageHandler}
                  >
                    Booked Now
                  </Button>
                </Container>
              </Grid>
              <Grid item xs={12} sm={6} md={6} style={{ position: "relative" }}>
                <img
                  src={contact}
                  style={{ width: matches ? "453px" : "auto" }}
                />
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Container>
    </Box>
  );
}

export default Contact;
