import React from "react";
import {
  Box,
  Container,
  Hidden,
  useMediaQuery,
  IconButton,
  SwipeableDrawer,
  Button,
  List,
  Divider,
  ListItem,
  ListItemText,
  Popper,
  Grow,
  MenuList,
  ClickAwayListener,
  Paper,
  MenuItem,
} from "@material-ui/core";
import soliditylogo from "../images/solidity audit.svg";
import { Link as LinkR } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import { Link as LinkS } from "react-scroll";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useWeb3React } from "@web3-react/core";
import { Person } from "@material-ui/icons";
import { defaultAddress } from "../utils";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    alignItems: "center",
  },
  paper: {
    background: "#171834",
    justifyContent: "center",
  },
});

function Header() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
  });
  const [openmenu, setOpenMenu] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenMenu(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenu(false);
    }
  }

  const { account, active, deactivate, activate } = useWeb3React();
  const matches = useMediaQuery("(max-width:960px)");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["about", "advantages", "services", "contact"].map((text, index) => (
          <ListItem
            button
            style={{
              justifyContent: "center",
              borderBottom: "1px solid #bbb8b8",
            }}
            key={text}
          >
            <LinkS to={text}>
              <ListItemText primary={text} />
            </LinkS>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box bgcolor="#171834" height="92px" boxShadow={1}>
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexBasis="64%"
          >
            <LinkR to="/" style={{ textDecoration: "none", color: "#fff" }}>
              <Box>
                <img src={soliditylogo} width="272px" alt="not found" />
              </Box>
            </LinkR>
            <Hidden mdDown>
              <Box fontSize="16px">
                <LinkS
                  to="about"
                  smooth={true}
                  offset={40}
                  duration={500}
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  About
                </LinkS>
              </Box>

              <Box fontSize="16px">
                <LinkS
                  smooth={true}
                  offset={50}
                  duration={500}
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                  to="advantages"
                >
                  Advantages
                </LinkS>
              </Box>
              <Box>
                <Box
                  fontSize="16px"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <LinkS
                    to="services"
                    smooth={true}
                    offset={-40}
                    duration={500}
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  >
                    Services
                  </LinkS>
                </Box>
              </Box>
              <Box fontSize="16px">
                <LinkS
                  to="process"
                  smooth={true}
                  offset={50}
                  duration={500}
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                >
                  Process
                </LinkS>
              </Box>
            </Hidden>
          </Box>
          <Box>
            {active && account.toLowerCase() == defaultAddress.toLowerCase() && (
              <>
                {" "}
                <IconButton ref={anchorRef} onClick={handleToggle}>
                  <Person fontSize="25px" style={{ color: "#fff" }} />
                </IconButton>
                <Popper
                  open={openmenu}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: "1" }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              component={LinkR}
                              to="/admin/projects"
                              onClick={handleClose}
                              style={{ color: "#000" }}
                            >
                              Admin Dashboard
                            </MenuItem>

                            <MenuItem
                              onClick={() => {
                                deactivate();
                                localStorage.removeItem("wallet");
                              }}
                              style={{ color: "#000" }}
                            >
                              Logout
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
            )}
            {/* <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
              <MenuIcon
                className={classes.drawerIcon}
                style={{ color: "black" }}
              />
            </IconButton> */}
          </Box>

          <Box>
            <Button
              style={{
                background: "#FFB800",
                display: matches ? "none" : "block",
                fontSize: "18px",
                borderRadius: "9px",
                width: "228px",
                height: "45px",
                color: "#fff",
                border: "none",
                textTransform: "capitalize",
              }}
            >
              <LinkS to="contact">Get Free Consultancy</LinkS>
            </Button>
            <Hidden mdUp>
              <IconButton>
                {["top"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                      <MenuIcon
                        style={{
                          fontSize: "38px",
                          cursor: "pointer",
                          color: "#fff",
                        }}
                      ></MenuIcon>
                    </Button>
                    <SwipeableDrawer
                      classes={{ paper: classes.paper }}
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      onOpen={toggleDrawer(anchor, true)}
                    >
                      {list(anchor)}
                    </SwipeableDrawer>
                    {/* <Drawer anchor={anchor} classes={{ paper: classes.paper }} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                    {list(anchor)}
                                </Drawer> */}
                  </React.Fragment>
                ))}
              </IconButton>
            </Hidden>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Header;
