import WebIcon from "@material-ui/icons/Web";
import EmailIcon from "@material-ui/icons/Email";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";

import Products from "./admin-dashboard/views/Products/Products";
import Addnew from "./admin-dashboard/views/Products/Addnew.js";
import Consultancy from "./admin-dashboard/views/consultancy/Consultancy";

const dashboardRoutes = [
  {
    path: "/projects",
    name: "Projects",
    icon: WebIcon,
    component: Products,
    layout: "/admin",
  },
  {
    path: "/add-new-project",
    name: "Add New Project",
    icon: AddBoxOutlinedIcon,
    component: Addnew,
    layout: "/admin",
  },
  {
    path: "/consultancy-requests",
    name: "Consultancy Requests",
    icon: EmailIcon,
    component: Consultancy,
    layout: "/admin",
  },
];

export default dashboardRoutes;
