import { Box, Button, useMediaQuery } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import React from "react";
import { injected } from "./connector";
export default function Connect() {
  const { account, deactivate, activate } = useWeb3React();
  const matches = useMediaQuery("(max-width:960px)");

  return (
    <Box align="center" bgcolor="#171834" py={10} my={10}>
      {account ? (
        <Button
          style={{
            background: "#FFB800",
            display: matches ? "none" : "block",
            fontSize: "18px",
            borderRadius: "9px",
            width: "228px",
            height: "45px",
            color: "#fff",
            border: "none",
            textTransform: "capitalize",
          }}
          onClick={() => {
            deactivate();
            localStorage.removeItem("wallet");
          }}
        >
          {account.slice(0, 6) + "..." + account.slice(-4)}
        </Button>
      ) : (
        <Button
          style={{
            background: "#FFB800",
            display: matches ? "none" : "block",
            fontSize: "18px",
            borderRadius: "9px",
            width: "228px",
            height: "45px",
            color: "#fff",
            border: "none",
            textTransform: "capitalize",
          }}
          onClick={() => {
            activate(injected);
            localStorage.setItem("wallet", "meta");
          }}
        >
          Connect
        </Button>
      )}
    </Box>
  );
}
